import React from 'react';
import classNames from 'classnames';
import { flowRight, isUndefined, get } from 'lodash';
import { ProGallery, GALLERY_CONSTS } from 'pro-gallery';
import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  isLayoutTextOnImage,
  isLayoutSlider,
  getVideoSettingsWithProGalleryValues,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  resolveId,
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_LAYOUT_FIXER } from '@wix/communities-blog-experiments';
import {
  isClickTargetItemMedia,
  isPGEventItemClicked,
} from '../../constants/pro-gallery-events';
import {
  TEXT_PLACEMENT,
  HOVERING_BEHAVIOUR,
  TEXT_BOX_ALIGNMENT_MAP,
  LAYOUT_IMAGE_RATIO_MAP,
} from '../../constants/pro-gallery-options';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import { useIsFeedDesignEnabled } from '../../hooks/use-is-feed-design-enabled';
import {
  getIsPostListFullWidthEnabled,
  getLayoutPostSize,
  getLayoutSpacing,
  getLayoutContentHeight,
  getFeedBorderColor,
  getLayoutImageRatio,
  getLayoutMargins,
  getLayoutImageAlignment,
  getLayoutImageProportions,
  getLayoutImageWidth,
  getLayoutImageResizingMode,
  getIsCreatedWithResponsiveEditor,
  getLayoutSidesPadding,
  getLayoutImageCropType,
  getUseMobileLayoutSettings,
  getLayoutCardsRowType,
  getLayoutPostsPerRow,
  getLayoutContentHeightType,
} from '../../selectors/app-settings-selectors';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import {
  getContainer,
  getPGEmptyCoverImage,
  getScrollingElement,
  getWrapperStyles,
  getIsPrerenderMode,
} from '../../services/post-list-pro-gallery';
import {
  getIsMobile,
  isSeo,
} from '../../store/basic-params/basic-params-selectors';
import { getPostPageSectionUrl } from '../../store/topology/topology-selectors';
import LoadMore from '../load-more/load-more';
import Loader from '../loader';
import { withResponsiveContext } from '../responsive-listener/responsive-context';
import { connect } from '../runtime-context';
import calculateContentHeight from './calculate-content-height';
import { createInfoRenderer } from './create-info-renderer';
import { createOptions } from './gallery-options';
import { mapPostToPGItem } from './map-post-to-pro-gallery-item';
import { resizeMediaUrl } from './resizeMediaUrl';
import { useScrollBase } from './use-scroll-base';
import styles from './post-list-pro-gallery.scss';

const PostListProGallery = (props) => {
  const {
    allPosts,
    borderColor,
    borderWidth,
    createdWithResponsiveEditor,
    hostWidth,
    layoutContentHeight,
    layoutImageAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    layoutImageRatio,
    layoutMargins,
    layoutName,
    layoutPostSize,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutSidesPadding,
    layoutSpacing,
    layoutType,
    layoutImageCropType,
    onLikeClick,
    rootWidth,
    section,
    hideAuthorBadge,
    canSeeMoreButton,
    className,
    hideCoverImage,
    layoutAutoHeightEnabled,
    layoutOptions = {},
    videoSettings,
    useMobilePostListLayout,
    visibleActions,
    loadMore,
    isLoading,
    entityCount,
    pageStart,
    domId = 'pro-blog',
    viewMode,
    isMobile,
    isSEO,
    navigateProGalleryWithinPostPage,
  } = props;

  const { isEditor, isRTL } = useEnvironment();
  const { experiments } = useExperiments();

  const deviceType = isMobile
    ? GALLERY_CONSTS.deviceType.MOBILE
    : GALLERY_CONSTS.deviceType.DESKTOP;

  const useLayoutFixerExperimentEnabled = experiments.enabled(
    EXPERIMENT_USE_LAYOUT_FIXER,
  );

  const { t } = useTranslation();
  const { getPostClassName } = useIsFeedDesignEnabled();

  React.useEffect(() => {
    if (createdWithResponsiveEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [createdWithResponsiveEditor]);

  const textBoxAlignment = React.useMemo(
    () => TEXT_BOX_ALIGNMENT_MAP[layoutImageAlignment],
    [layoutImageAlignment],
  );

  const layoutImageRatioValue = React.useMemo(
    () => LAYOUT_IMAGE_RATIO_MAP[layoutImageRatio],
    [layoutImageRatio],
  );

  const options = React.useMemo(() => {
    const container = getContainer({
      borderWidth,
      createdWithResponsiveEditor,
      isMobile,
      layoutContentHeight,
      layoutImageRatioValue,
      layoutMargins,
      layoutPostSize,
      layoutType,
      hostWidth,
      rootWidth,
      section,
      useLayoutFixerExperimentEnabled,
      useMobilePostListLayout,
      isEditor,
    });

    return createOptions(
      layoutType,
      {
        layoutPostSize: isLayoutSlider(layoutType) ? hostWidth : layoutPostSize,
        layoutCardsRowType,
        layoutPostsPerRow,
        layoutSpacing,
        layoutContentHeight,
        layoutImageCropType,
        layoutImageRatio: layoutImageRatioValue,
        textBoxAlignment,
        layoutImageProportions,
        layoutImageWidth,
        layoutImageResizingMode,
        isRTL,
        videoSettings,
        containerWidth: container.width,
        hideCoverImage,
        layoutAutoHeightEnabled,
        ...layoutOptions,
      },
      {
        width: borderWidth,
        color: borderColor,
      },
    );
  }, [
    borderWidth,
    createdWithResponsiveEditor,
    isMobile,
    layoutContentHeight,
    layoutImageRatioValue,
    layoutMargins,
    layoutPostSize,
    layoutType,
    hostWidth,
    rootWidth,
    section,
    useLayoutFixerExperimentEnabled,
    useMobilePostListLayout,
    isEditor,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutSpacing,
    layoutImageCropType,
    textBoxAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    isRTL,
    videoSettings,
    hideCoverImage,
    layoutAutoHeightEnabled,
    layoutOptions,
    borderColor,
  ]);

  const isPrerenderMode = React.useMemo(
    () =>
      getIsPrerenderMode({
        hostWidth,
        rootWidth,
        isPrerenderMode: true,
        isSEO,
      }),
    [hostWidth, isSEO, rootWidth],
  );

  const eventsListener = React.useCallback(
    (eventName, eventData) => {
      if (
        !isLayoutTextOnImage(layoutType) &&
        isPGEventItemClicked(eventName) &&
        isClickTargetItemMedia(eventData)
      ) {
        const currentPost = allPosts.find(
          (post) => resolveId(post) === eventData.id,
        );

        if (
          !currentPost?.slug ||
          (currentPost.coverImage?.videoMetadata &&
            videoSettings.play === 'onClick')
        ) {
          return;
        }

        navigateProGalleryWithinPostPage(currentPost.slug);
      }
    },
    [allPosts, layoutType, videoSettings, navigateProGalleryWithinPostPage],
  );

  const infoRenderer = React.useMemo(
    () =>
      createInfoRenderer({
        allPosts,
        layoutType,
        layoutName,
        section,
        layoutSidesPadding,
        canSeeMoreButton,
        hideAuthorBadge,
        textBoxAlignment,
        options,
        onLikeClick,
        getPostClassName,
        visibleActions,
      }),
    [
      allPosts,
      layoutType,
      layoutName,
      section,
      layoutSidesPadding,
      canSeeMoreButton,
      hideAuthorBadge,
      textBoxAlignment,
      options,
      onLikeClick,
      getPostClassName,
      visibleActions,
    ],
  );

  const customComponents = React.useMemo(() => {
    if (!infoRenderer) {
      return undefined;
    }

    return {
      customHoverRenderer:
        options.hoveringBehaviour === HOVERING_BEHAVIOUR.NEVER_SHOW
          ? null
          : infoRenderer,
      customInfoRenderer: infoRenderer,
    };
  }, [infoRenderer, options.hoveringBehaviour]);

  const items = React.useMemo(() => {
    const emptyCoverImage = getPGEmptyCoverImage(layoutType);
    return allPosts.map((post) =>
      mapPostToPGItem(post, hideCoverImage, emptyCoverImage),
    );
  }, [allPosts, hideCoverImage, layoutType]);

  const PostListProGalleryComponent = React.memo(() => {
    const container = getContainer({
      borderWidth,
      createdWithResponsiveEditor,
      isMobile,
      layoutContentHeight,
      layoutImageRatioValue,
      layoutMargins,
      layoutPostSize,
      layoutType,
      hostWidth,
      rootWidth,
      section,
      useLayoutFixerExperimentEnabled,
      useMobilePostListLayout,
      isEditor,
    });

    const wrapperStyles = getWrapperStyles({
      container,
      createdWithResponsiveEditor,
      isMobile,
      layoutMargins,
      layoutPostSize,
      layoutType,
      section,
    });

    const containerRef = React.useRef(null);
    const scrollBase = useScrollBase(containerRef);

    return (
      <div
        key="pg-container"
        data-hook="post-list-pro-gallery-container"
        style={wrapperStyles}
        className={classNames(
          `post-list-pro-gallery-${layoutName}`,
          { 'is-mobile': isMobile },
          section,
          isMobile && items.length === 1 ? 'post-list-pro-gallery-flat' : '',
          textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
            ? 'gallery-left-align'
            : 'gallery-right-align',
          styles.overrides,
          className,
        )}
        ref={containerRef}
      >
        <ProGallery
          key={`pg-posts-${layoutPostSize}`}
          id={domId}
          items={items}
          totalItemsCount={items.length}
          options={options}
          container={{ ...container, scrollBase }}
          scrollingElement={getScrollingElement()}
          eventsListener={eventsListener}
          viewMode={viewMode}
          deviceType={deviceType}
          customComponents={customComponents}
          createMediaUrl={resizeMediaUrl}
          proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
          isPrerenderMode={isPrerenderMode}
          shouldDisableItemFocus={true}
        />
        {useLayoutFixerExperimentEnabled && isPrerenderMode ? (
          <LayoutFixer
            id={domId}
            items={items}
            isPrerenderMode={isPrerenderMode}
            options={options}
          />
        ) : null}
      </div>
    );
  });

  return !loadMore ? (
    <PostListProGalleryComponent />
  ) : (
    <LoadMore
      loadMore={loadMore}
      loader={<Loader />}
      isLoading={isLoading}
      hasMore={allPosts.length < entityCount}
      pageStart={pageStart}
    >
      <PostListProGalleryComponent />
    </LoadMore>
  );
};

const getLayoutStyleParamProps = ({
  state,
  section,
  hostWidth,
  rootWidth,
  layoutMargins,
  layoutType,
}) => {
  const contentHeightType = getLayoutContentHeightType({
    state,
    section,
    layoutType,
  });
  const layoutAutoHeightEnabled =
    contentHeightType === PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO;
  const selectorProps = { state, section, layoutType };
  return {
    layoutAutoHeightEnabled,
    layoutContentHeight: layoutAutoHeightEnabled
      ? calculateContentHeight(state, section, layoutType)
      : getLayoutContentHeight(selectorProps),
    layoutImageAlignment: getLayoutImageAlignment(selectorProps),
    layoutImageProportions: getLayoutImageProportions(selectorProps),
    layoutImageWidth: getLayoutImageWidth(selectorProps),
    layoutImageResizingMode: getLayoutImageResizingMode(selectorProps),
    layoutImageCropType: getLayoutImageCropType(selectorProps),
    layoutImageRatio: getLayoutImageRatio(selectorProps),
    layoutCardsRowType: getLayoutCardsRowType(selectorProps),
    layoutPostsPerRow: getLayoutPostsPerRow(selectorProps),
    layoutPostSize: getLayoutPostSize({
      state,
      hostWidth,
      rootWidth,
      layoutMargins,
      section,
      layoutType,
    }),
    layoutSidesPadding: getLayoutSidesPadding(selectorProps),
    layoutSpacing: getLayoutSpacing(selectorProps),
  };
};

const mapRuntimeToProps = (
  state,
  { section, layoutType, layoutName, rootWidth, layoutDefaults = {} },
  actions,
  host,
) => {
  const hostWidth = get(host, 'dimensions.width');
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  const isFeed = [SECTION_HOMEPAGE, SECTION_CATEGORY].includes(section);
  const useResponsiveEditorStyling = createdWithResponsiveEditor && !isFeed;

  const layoutMargins = useResponsiveEditorStyling
    ? null
    : getLayoutMargins({ state, section, layoutType, layoutName });
  const {
    layoutContentHeight,
    layoutImageAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    layoutImageRatio,
    layoutPostSize,
    layoutSidesPadding,
    layoutImageCropType,
    layoutSpacing,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutAutoHeightEnabled,
  } = getLayoutStyleParamProps({
    state,
    rootWidth,
    hostWidth,
    layoutMargins,
    section,
    layoutType,
  });
  const borderColor = getFeedBorderColor({ state, section });

  return {
    scroll: state.scroll,
    isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
    onLikeClick: actions.incrementPostLikeCount,
    postPageSectionUrl: getPostPageSectionUrl(state),
    hostWidth,
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    borderColor:
      typeof borderColor === 'string'
        ? borderColor
        : borderColor.value || borderColor.themeName,
    createdWithResponsiveEditor,
    layoutContentHeight: isUndefined(layoutContentHeight)
      ? layoutDefaults.contentHeight
      : layoutContentHeight,
    layoutImageAlignment: isUndefined(layoutImageAlignment)
      ? layoutDefaults.imageAlignment
      : layoutImageAlignment,
    layoutImageProportions: isUndefined(layoutImageProportions)
      ? layoutDefaults.imageProportions
      : layoutImageProportions,
    layoutImageWidth: isUndefined(layoutImageWidth)
      ? layoutDefaults.layoutImageWidth
      : layoutImageWidth,
    layoutImageResizingMode: isUndefined(layoutImageResizingMode)
      ? layoutDefaults.layoutImageResizingMode
      : layoutImageResizingMode,
    layoutImageRatio: isUndefined(layoutImageRatio)
      ? layoutDefaults.imageRatio
      : layoutImageRatio,
    layoutImageCropType: isUndefined(layoutImageCropType)
      ? layoutDefaults.imageCropType
      : layoutImageCropType,
    layoutMargins: isUndefined(layoutMargins)
      ? layoutDefaults.margins
      : layoutMargins,
    layoutPostSize: isUndefined(layoutPostSize)
      ? layoutDefaults.layoutPostSize
      : layoutPostSize,
    layoutCardsRowType: isUndefined(layoutCardsRowType)
      ? layoutDefaults.layoutCardsRowType
      : layoutCardsRowType,
    layoutPostsPerRow: isUndefined(layoutPostsPerRow)
      ? layoutDefaults.layoutPostsPerRow
      : layoutPostsPerRow,
    layoutAutoHeightEnabled: isUndefined(layoutAutoHeightEnabled)
      ? layoutDefaults.layoutAutoHeightEnabled
      : layoutAutoHeightEnabled,
    layoutSidesPadding: isUndefined(layoutSidesPadding)
      ? layoutDefaults.sidesPadding
      : layoutSidesPadding,
    layoutSpacing: isUndefined(layoutSpacing)
      ? layoutDefaults.spacing
      : layoutSpacing,
    registerToScroll: host.registerToScroll,
    viewMode: getProGalleryViewMode(state, rootWidth),
    videoSettings: getVideoSettingsWithProGalleryValues(state, section),
    useMobilePostListLayout: getUseMobileLayoutSettings(state),
    isMobile: getIsMobile(state),
    isSEO: isSeo(state),
    rootWidth,
  };
};

export default flowRight(
  withFeedMetadataSettings,
  withResponsiveContext,
  connect(mapRuntimeToProps),
  withFeedBorderWidth,
)(PostListProGallery);
